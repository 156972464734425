<template>
  <v-row justify="center">
    <v-dialog
      v-model="open"
      transition="scale-transition"
      persistent
      scrollable
      max-width="350px"
    >
      <v-card outlined>
        <v-card-title class="title">
          {{ $t("app.add") }} {{ $t("e_test.question") }}
          <div style="position: absolute; right: 25px;">
            <v-icon :disabled="loading" @click="toggle">mdi-close</v-icon>
          </div>
        </v-card-title>
        <v-divider />
        <v-card-text>
          <v-row style="cursor: pointer" class="pt-4" @click="toggleAddManual">
            <v-col cols="12" sm="10">
              {{ $t("e_test.add_questions_manually") }}
            </v-col>
            <v-col cols="12" sm="2">
              <v-icon color="primary">mdi-arrow-right</v-icon>
            </v-col>
          </v-row>
          <v-divider class="my-4" />
          <v-row>
            <v-col cols="12" class="mb-2">
              {{ $t("e_test.upload_excel") }}
            </v-col>
            <v-col cols="12">
              <div
                v-cloak
                :class="darkmode ? 'grey darken-4' : 'grey lighten-4'"
                style="height: 150px; cursor: pointer"
                class="flex-column align-center justify-center d-flex rounded-lg"
                @drop.prevent="uploadFile"
                @dragover.prevent
                @click="onButtonClick"
              >
                <v-icon color="teal darken-3" large>mdi-file-excel</v-icon>
                <div v-if="file">
                  <span class="caption">{{ file.name }}</span>
                </div>
                <div v-else>
                  <span class="caption">{{ $t("e_test.drag") }}</span>
                </div>
                <input
                  ref="uploader"
                  class="d-none"
                  type="file"
                  accept=".xlsx, .xls"
                  @change="uploadFile"
                />
              </div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-row no-gutters justify="end">
            <v-btn
              :disabled="loading"
              outlined
              small
              color="primary"
              depressed
              @click="toggle"
            >
              {{ $t("app.cancel") }}
            </v-btn>
            <v-spacer />
            <v-btn
              :disabled="disabledBtnAdd"
              :loading="loading"
              class="gradient-primary"
              outlined
              small
              dark
              depressed
              @click="saveExcel"
            >
              {{ $t("app.save") }}
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <AddManual
      :open="addManual"
      :subjects="subjects"
      isGlobal
      @toggleDialog="toggleAddManual"
    />
    <DialogUploadFailed
      :dialog="dialogUploadFailed"
      :questionErr="questionError"
      :questionSuccess="questionSuccess"
      @addSuccess="item => questionSuccess.push(item)"
      @close="
        {
          dialogUploadFailed = false;
          toggle();
        }
      "
    />
  </v-row>
</template>

<script>
import store from "@/store";
import XLSX from "xlsx";
import { setQuestionBankGlobalExcel } from "@/api/admin/academic/eTest";
const keyPG = [
  "a",
  "b",
  "c",
  "d",
  "e",
  "f",
  "g",
  "h",
  "i",
  "j",
  "k",
  "l",
  "m",
  "n",
  "o",
  "p",
  "q",
  "r",
  "s",
  "t",
  "u",
  "v",
  "w",
  "x",
  "y",
  "z"
];

const headerExcel = [
  { name: "periode", translate: "period" },
  { name: "tingkatan", translate: "grade" },
  { name: "semester", translate: "semester" },
  { name: "mata_pelajaran", translate: "subjects" },
  { name: "jenis_soal", translate: "question_type" },
  { name: "soal", translate: "question" },
  { name: "jawaban", translate: "answer" },
  { name: "bobot_nilai", translate: "point" },
  { name: "pembahasan_soal", translate: "discuss" },
  { name: "kata_kunci", translate: "keyword" }
];

export default {
  props: {
    open: Boolean,
    subjects: Object
  },
  components: {
    AddManual: () => import("../components/AddManual"),
    DialogUploadFailed: () => import("./DialogUploadFailed")
  },
  watch: {
    open(value) {
      if (value) {
        this.questionError = [];
        this.questionSuccess = [];
      }
    },
    addManual(value) {
      if (!value) {
        this.toggle();
      }
    },
    file(value) {
      if (value) this.disabledBtnAdd = false;
      else this.disabledBtnAdd = true;
    }
  },
  computed: {
    darkmode() {
      return store.getters.g_darkmode;
    }
  },
  data() {
    return {
      disabledBtnAdd: true,
      addManual: false,
      loading: false,
      file: null,
      dialogUploadFailed: false,
      questionError: [],
      questionSuccess: []
    };
  },
  methods: {
    validateHeader(header) {
      let msg_error = "";
      let isError = false;
      headerExcel.map(item => {
        if (!header.includes(item.name)) {
          msg_error = this.$i18n.t(`e_test.msg_excel.${item.translate}`);
          isError = true;
        }
      });
      if (isError) {
        this.snackBar(false, msg_error);
        return false;
      } else return true;
    },
    saveExcel() {
      this.loading = true;
      return new Promise(resolve => {
        const reader = new FileReader();
        reader.onload = async e => {
          const data = e.target.result;
          const workbook = XLSX.read(data, { type: "array" });
          const sheetName = "Bank Soal";
          let worksheet = [];
          let header = [];

          if (workbook.SheetNames.some(sn => sn === sheetName)) {
            worksheet = workbook.Sheets[sheetName];
            header = this.getHeaderRow(worksheet);
            const results = XLSX.utils.sheet_to_json(worksheet, {
              header: header,
              range: 1,
              defval: ""
            });
            if (this.validateHeader(header)) {
              const questionError = results
                .map(item => this.getJson(item))
                .map(async json => {
                  const response = await setQuestionBankGlobalExcel(json);
                  if (response.data.code) {
                    this.questionSuccess.push(json);
                  } else {
                    return { ...json, error: response.data.message };
                  }
                });

              // filter failed data
              this.questionError = (await Promise.all(questionError)).filter(
                item => typeof item === "object" && item !== null
              );
              this.dialogUploadFailed = true;
            }
          } else {
            this.snackBar(false, "Sheet Bank Soal tidak ada");
          }

          this.resetFile();
          this.loading = false;
          resolve();
        };
        reader.readAsArrayBuffer(this.file);
      });
    },
    getJson(data) {
      try {
        return {
          class: [],
          question_file: [],
          kd: "",
          semester: data.semester,
          point: data.bobot_nilai,
          periode: data.periode,
          question: data.soal,
          grade: data.tingkatan,
          discussion: data.pembahasan_soal,
          lesson: data.mata_pelajaran.trim(),
          tags: data.kata_kunci,
          ...this.getAnswerBody(data)
        };
      } catch (error) {
        console.error("getBodyRequest()\n", error);
      }
    },
    getAnswerBody(data) {
      let result = {};
      switch (data.jenis_soal) {
        case "pg":
          result = {
            pg: this.getListPG(data),
            type: data.jenis_soal
          };
          break;
        case "essay":
        case "esay":
          result = {
            esay: {
              key_file: [],
              key: data.jawaban
            },
            type: "esay"
          };
          break;
        default:
          throw new Error("Jawaban harus diisi ", data.jenis_soal);
      }
      return result;
    },
    getListPG(data) {
      /* 
      create object pg for body request
      example data :
      keyData = "pg_a"
      data.jawaban = "a,b" || "a"
      Object.keys(data) = ["jawaban", "pg_a", "pg_b"] (header excel)
      */
      return Object.keys(data)
        .filter(item => keyPG.some(key => `pg_${key}` === item))
        .map(keyData => ({
          answer_file: [],
          answer: data[keyData],
          key: data.jawaban
            .split(",")
            .some(answer => answer.toLowerCase() === keyData.split("_")[1]),
          code: keyData.split("_")[1].toLowerCase()
        }));
    },
    format_column_name(name) {
      return name
        .toLowerCase()
        .trim()
        .replace(/\s/g, "_");
    },
    getHeaderRow(sheet) {
      const headers = [];
      const range = XLSX.utils.decode_range(sheet["!ref"]);
      let C;
      const R = range.s.r;
      /* start in the first row */
      for (C = range.s.c; C <= range.e.c; ++C) {
        const cell = sheet[XLSX.utils.encode_cell({ c: C, r: R })];
        let hdr = "UNKNOWN " + C;
        if (cell && cell.t) hdr = XLSX.utils.format_cell(cell);
        headers.push(this.format_column_name(hdr));
      }
      return headers;
    },
    snackBar(isSuccess, msg) {
      this.$store.commit("CALL_SNACKBAR", {
        msg: msg,
        color: isSuccess ? "success" : "error"
      });
    },
    onButtonClick() {
      if (!this.loading) {
        window.addEventListener("focus", () => {}, { once: true });
        this.$refs.uploader.click();
      }
    },
    checkfile(fileExt) {
      const validExts = new Array(".xlsx", ".xls");
      fileExt = fileExt.substring(fileExt.lastIndexOf("."));
      if (validExts.indexOf(fileExt) < 0) {
        this.snackBar(false, this.$i18n.t("e_test.msg.valid_type_upload"));
        return false;
      } else return true;
    },
    uploadFile(e) {
      let droppedFiles;
      if (!this.loading) {
        switch (e.type) {
          case "drop":
            droppedFiles = e.dataTransfer.files;
            break;
          case "change":
            droppedFiles = e.target.files;
            break;
        }
        // check total file
        if (droppedFiles.length === 1) {
          // check file type
          if (this.checkfile(droppedFiles[0].name)) {
            this.file = droppedFiles[0];
            return true;
          } else {
            return false;
          }
        } else {
          this.snackBar(false, this.$i18n.t("e_test.msg.max_upload_excel"));
          return false;
        }
      }
    },
    toggleAddManual() {
      if (!this.loading) this.addManual = !this.addManual;
    },
    resetFile() {
      this.$refs.uploader.value = "";
      this.file = null;
    },
    toggle() {
      this.resetFile();
      this.$emit("toggleDialog");
    }
  }
};
</script>
